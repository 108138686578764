.ticket-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensures it takes the full viewport height */
  width: 100%; /* Full width to avoid any centering issues */
  padding: 0; /* Remove any padding if present */
  margin: 0; /* Remove any margin if present */
  box-sizing: border-box; /* Ensure padding doesn't affect height */
}

.ticket {
  background-color: #fff;
  max-width: 350px;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.ticket-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
}

.event-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.event-details {
  text-align: left;
}

.event-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.event-place, .event-date {
  font-size: 0.85rem;
  color: #000;
  margin: 2px 0;
}

.booking-id {
  background-color: #000;
  color: #fff;
  font-weight: 500;
  font-size: 0.85rem;
  padding: 6px 12px;
  margin: 16px 0;
  border-radius: 20px;
  display: inline-block;
}

.qr-code {
  margin: 16px 0;
}

.ticket-type {
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 8px;
}

.ticket-quantity, .booking-date {
  font-size: 0.85rem;
  color: #000;
  margin: 4px 0;
}

.amount {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  margin-top: 16px;
}
