.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    
    
}
body{
    background: #000000  !important;
}
.confirm_page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #000000 !important;
   

}

.confirm_box{
    background-color:#1a1a1ada;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    min-height: 200px;
    padding: 10px;
    border-radius: 20px;
    
   
}

.confirm_box h3{
    border-bottom: 1px solid grey;
}

.table{
    max-width: 70% !important;
    margin-top: 10px;
    color: #ffffff;
    font-family:"verdana";
    
}


.custom-button {
    color: white !important;
    background-color: #ff0000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.custom-button:hover {
    color: white !important;
    background-color: #000000 !important;
  }

  @media (max-width: 768px) {
    .confirm_box {
        width: 95%; /* Adjust width for smaller screens */
    }
    

    .table {
        font-size: 14px; /* Decrease font size for smaller screens */
    }

    .custom-button {
        padding: 8px 16px; /* Adjust padding for smaller screens */
        margin-top: 15px; /* Add margin for separation */
        
    }
    .confirm_page{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        background-color: #000000;
    }

}



  

.holder_catg{
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    gap: 10px;
    width: 100%;
    background: white;
    border-radius: 5px;
    

}

.holder_sub_div{
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
    min-height: 50px;
    padding: 4px 6px;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
}

.holder_sub_div:hover{
    box-shadow: 0px 2px 5px #e3e3e3;

}

.holder_leftside{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.cust_btn{
    background: black !important;
    color: white !important;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 4px 10px;
    font-size: 12px !important;
}


@media (max-width: 600px) {
    

    
.holder_sub_div:hover{
    box-shadow: 0px 2px 5px #696969 !important;

}

}

@media (max-width: 768px) {
    .custom-button {
        position: fixed !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
        text-align: center;
        padding: 12px 0 !important;
        font-size: 16px !important;
        font-weight: bold !important;
        z-index: 999 !important; /* Ensure it's on top */
    }
}
