.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
}

.confirm_page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    
}

.confirm_box{
    background-color:#ffffffda !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    min-height: 200px;
    padding: 10px;
    border-radius: 5px !important;
   
}

.confirm_box h3{
    border-bottom: 1px solid grey;
}

.table{
    max-width: 70% !important;
    margin-top: 10px;
    color: #ffffff;
    font-family:"verdana";
}


.custom-button {
    color: white !important;
    background-color: #ff0000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.custom-button:hover {
    color: white !important;
    background-color: #000000 !important;
  }

  .custom_div{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 90%;
    padding: 20px 10px;
    
}

.coupon_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1px;
    width:100%;
    padding: 10px 10px -10px 10px;
    background-color: #ffffff;
}

.coupon{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 8px;
}

.custom_input{
    width: 100%;
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 8px;
    border-radius: 4px;
}

.coupon button{
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 8px;
    background-color: #e7e7e7;
    border-radius: 4px;
}

.coupon_status{
    margin-left: 13px;
    font-size: .8rem;
    margin-top: 5px;
}


.end{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.custom_hr{
    height: 2px !important;
    border-bottom: 1px solid rgb(142, 142, 142) !important;
    width: 100%;
}

  @media (max-width: 768px) {
    .confirm_box {
        width: 95%; /* Adjust width for smaller screens */
    }
    

    .table {
        font-size: 14px; /* Decrease font size for smaller screens */
    }

    .custom-button {
        padding: 8px 16px; /* Adjust padding for smaller screens */
        margin-top: 15px; /* Add margin for separation */
        
    }
    .confirm_page{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        background-color: #000000;
    }
}

@media (max-width:600px) {

    .confirm_box{
        background: white !important;
    }
    
}

