

.qr_div{
    height: 50vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 10% !important;
    aspect-ratio: 1/1 !important;
}

.qr{
    height: 70% !important;
    width: 70% !important;
    border: 1px solid white;
    padding: 1px !important;
}

.make_center{
    justify-content: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}