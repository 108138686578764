.booking-container {
  padding-top: 70px; 
  color: white; 
  background-color: #000;
  max-width: 600px; /* Limit the max width on all screens */
  margin: 0 auto; /* Center-align the container */
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.booking-wrapper {
  width: 100%;
}

.order-date {
  font-size: 14px;
  color: #bbb;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
}

.booking-card {
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
}

.booking-details {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

.event-image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 15px;
  object-fit: cover;
}

.event-info {
  flex: 1;
  margin-top: 20px;
}

.event-name {
  font-size: 18px;
  color: #ff4b4b;
  margin: 0;
  font-weight: bold;
}

.eventnew-date,
.ticket-count,
.amount {
  font-size: 14px;
  margin: 5px 0;
  color: #fff;
}

.amountnew {
  font-weight: bold;
}

.separator {
  height: 2px;
  background-color: gray;
  margin: 10px 0;
  border-radius: 2px;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-bar {
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.status-bar.confirmed {
  background-color: #ff4b4b;
  color: #fff;
}

.status-bar.failed {
  background-color: #ff4b4b;
  color: #fff;
}
.status-bar.finished {
  background-color: #ff4b4b; /* Red background for finished */
  color: #fff;
}

.status-message {
  margin-top: 14px;
  font-size: 14px;
  color: #bbb;
  margin-left: 10px;
}

/* Responsive design for larger screens */
@media (min-width: 1024px) {
  .booking-container {
    width: 40%; /* Center-align the container on larger screens */
  }

  .event-image {
    width: 100px;
    height: 100px;
  }

  .event-name {
    font-size: 20px;
  }

  .event-date,
  .ticket-count,
  .amount {
    font-size: 16px;
  }

  .status-bar {
    font-size: 16px;
  }
}
