.image_div .carousel-inner{
    width: 100vw !important;
}

.display_sec .carousel-control-next,.display_sec .carousel-control-prev{
	background: rgba(24, 21, 21, 0.614) !important;
    border-radius: 50% !important;
    width: 100px !important;
    height: 100px !important;
	top: 40% !important;
    bottom: 38% !important;
}

.carousel-div {
	background-color: #000000;
}

.upcoming-banner {
	background: linear-gradient(to right, red, black);
	height: fit-content;
}

.upcoming-banner-width {
	width: 300px;
}

.upcoming-banner-width p {
	font-size: 30px;
	font-weight: 700;
	margin-left: 20px;
	padding: 10px;
}

.upcoming-banner-mobile {
	margin-top: 20px;
	width: 200px;
}

.upcoming-banner-mobile p {
	font-size: 16px;
	font-weight: 700;
	margin-left: 5px;
	padding: 7px;
}

.MuiGrid-container {
	background-color: #000000 !important;
}

.carousel-item {
	background-color: #000000 !important;
}

.display_sec .carousel-indicators{

	display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
	position: absolute;
    bottom: -40px;
	

    
}

	li.active::marker {
	background-color: red !important;
}


.loader{
    font-size: 20px;
    color: #c1082d;
}


/* .spinner {
	--t: 2500ms;
	--animation: rotate var(--t) linear infinite;
	--animation2: scale var(--t) linear infinite alternate;
	position: relative;
	width: 10rem;
	height: 10rem;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: var(--animation), var(--animation2);
}

.spinner::before,
.spinner::after {
	content: '';
	position: absolute;
}

.spinner::before {
	inset: 0;
	border: 3px solid rgb(204, 10, 10);
	border-radius: 50%;
	mask-image: conic-gradient(transparent 10%, black);
	animation: borderScale var(--t) linear infinite alternate;
}

.spinner::after {
	--r: 45deg;
	--scale: 2;
	width: 20%;
	height: 20%;
	background: 
		radial-gradient(circle at 30% 35%, white 3px, transparent 0),
		radial-gradient(circle at 70% 35%, white 3px, transparent 0),
		radial-gradient(circle at top center, white 6px, transparent 0),
		rgb(169, 5, 13);
	background-position: 0 0, 0 0, 0 1.25rem;
	top: 0;
	translate: 0 -50%;
	rotate: 45deg;
	animation: var(--animation) reverse, var(--animation2);
	border-radius: 20%;
}

@keyframes rotate {
	to {
		rotate: calc(360deg + var(--r, 0deg));
	}
}

@keyframes scale {
	to {
		scale: var(--scale, 0.5);
	}
}

@keyframes borderScale {
	to {
		border: 6px solid turquoise;
	}
}

.make_center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} */

.loader {
    font-size: 20px;
	font-family: 'Lucida Sans';
    color: #000000;
}

.spinner {
    position: relative;
    width: 10rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner img {
    width: 100%; /* Set the width of the image to fill the spinner container */
    height: auto; /* Maintain the aspect ratio of the image */
    animation: pulsate 2.5s infinite; /* Apply the pulsating animation */

}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.make_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.search_inp{
	background-color: white;
	height: 80%;
	width: 60%;
	padding: 10px;
	border-radius: 5px;
}

.search_div{
	display: flex;
	justify-content: center;
}


/* @media (max-width: 600px) {
	.search_cust_img{
		height: 150px;
	}
  .search_inp {
    width: 100% !important;
  }

  .search_div{
	margin-top: -50px !important;
  }
} */

/* NEW CSS */

/* Ensure larger height for desktop */
@media (min-width: 768px) {
    .fixed-image-container {
        height: 500px !important; /* Adjust for larger screens */
    }
}


@media (max-width: 600px) {
    /* Reset container styles */
    body, main {
        margin: 0 !important;
        padding: 0 !important;
        /* overflow-x: hidden !important; */
		overflow: visible !important;
        width: 100vw !important;
    }

	
	.fixed-image-container {
		width: 100% !important;
		height: 500px !important;  /* Ensures a consistent height */
		background-size: cover !important;  /* Fills the container */
		background-position: center !important;
		object-fit: cover !important;
	}
	
    /* Image styles for mobile */
    /* .search_cust_img {
        width: 100% !important;
        height: 320px !important;
        object-fit: cover !important;
        margin: 0 !important;
        padding: 0 !important;
        display: block !important;
    } */

	.search_cust_img {
		width: 100% !important;
		height: auto !important;
		object-fit: contain !important;
	}
	

    /* SwipeableViews styles */
    .react-swipeable-views-root {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .react-swipeable-views-container {
        width: 100% !important;
        height: 320px !important;
    }

    .react-swipeable-views-container > div {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* Mobile container styles */
    div[style*="background: black"] {
        width: 100vw !important;
        min-height: 100vh !important;
        padding: 0 !important;
        margin: 0 !important;
        overflow: hidden !important;
    }

    /* Banner styles for mobile */
    .upcoming-banner-mobile {
        margin: 20px 20px 0 !important;
        width: auto !important;
    }

    /* Search styles for mobile */
    .search_inp {
        width: 100% !important;
    }

    .search_div {
        margin-top: -50px !important;
    }

    /* Image container styles */
    /* .carousel-item img {
        width: 100% !important;
        height: 320px !important;
        object-fit: cover !important;
    } */

	.carousel-item img {
		width: 100% !important;
		height: auto !important;
		object-fit: contain !important;
	}

	.carousel_div {
		overflow: visible !important; /* Ensure no cropping from the parent */
	}
	
}