
.MuiContainer-root.product-view {
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}
.product-view .image-wrapper img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 90%;
}
@media (max-width: 767px) {
  .product-view .expansion-panel {
    background-color: black;
    color: white;
    justify-content: center;
    align-items: center;
  }
}

.product-view .expansion-panel-summary {
  background-color: black;
  color: white;
}

.product-view .text {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  color: #000;
}

.product-view .text h2 {
  color: #ff0000;
  font-size: 35px;
  padding-bottom: 30px;
}

.product-view .text h3 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
}

.MuiContainer-root.product-view .custom-button {
  color: white;
  background-color: #1c2331;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiContainer-root.product-view .custom-button:hover {
  color: white;
  background-color: #2a344a;
}

.product-view .MuiGrid-root .MuiGrid-item {
  padding: 5px 15px;
}

.carousel-caption {
  top: 45%;
}

.d-block {
  height: 50vh;
  opacity: 100%;
}

@media (min-width: 960px) {
  .MuiContainer-root.product-view {
    height: 88vh;
  }
}


.unique-color-dark {
  background-color: #000000 !important;
}

@media (max-width: 768px) {
  .product-view {
    max-width: 100%; /* Ensure the container doesn't exceed the viewport width */
    overflow-x: hidden; /* Hide horizontal overflow */
    padding: 0 10px; /* Adjust padding for smaller screens if necessary */
  }
}

/* style.css */
/* Reset expansion panel styles */
.MuiExpansionPanelDetails-root,
.MuiCollapse-wrapper,
.MuiCollapse-wrapperInner,
#panel1a-content,
.MuiExpansionPanelDetails-root > p,
.MuiTypography-body1 {
  display: block !important;
  max-width: 100% !important;
  overflow: visible !important;
  padding: 0 !important;
}

/* Artist list container */
.artist-list {
  width: 100%;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch;
  padding: 16px 0;
  margin: 0;
}

/* Force horizontal scroll */
.artist-list-items {
  display: inline-flex !important;
  flex-wrap: nowrap !important;
  list-style: none;
  padding: 0;
  margin: 0;
  width: max-content !important;
  min-width: min-content !important;
}

.artist-item {
  flex: 0 0 auto !important;
  margin-right: 20px;
  text-align: center;
  width: 70px;
}

.artist-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 8px;
}

/* Make text visible */
.artist-item b {
  color: white;
  display: block;
  font-size: 14px;
  white-space: nowrap;
}

/* Scrollbar styling */
.artist-list::-webkit-scrollbar {
  height: 4px;
}

.artist-list::-webkit-scrollbar-track {
  background: rgba(0, 5, 1, 0.934);
}

.artist-list::-webkit-scrollbar-thumb {
  background: rgba(3, 0, 0, 0.3);
  border-radius: 2px;
}

.custom-container{
  height: auto !important;
}

.image_btn{
  border: none;
  outline: none;
  border-radius: 4px;
  background: rgb(236, 236, 236);
  color: rgb(174, 174, 174);
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .product-view.custom-container {
    background-color: #000000;
    color: #000000;
     margin-top: 0px;
     padding-top:55px;
     padding-left: 0px;
     padding-right: 0px;
   
  }
}

@media (max-width: 767px) {
  .product-view .text h2 {
    color: #ffffff;
    font-size: 35px;
    padding-bottom: 30px;
  }
}
@media (max-width: 600px) {

  .product-view .image-wrapper {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .MuiPaper-root.MuiCard-root.makeStyles-root-14.MuiPaper-elevation1.MuiPaper-rounded {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 8px !important;
    overflow: hidden !important;
  }

/* 
  .MuiCardMedia-root.makeStyles-media-15 {
    width: 100% !important;
    height: 400px !important; /* Increased height for better visibility */
    /* object-fit: cover !important;
    aspect-ratio: 3/4;
    background-size: cover !important;
    background-position: center !important;
  } */ 

  .MuiCardMedia-root.makeStyles-media-15 {
    width: 100% !important;
    height: auto !important; /* Let the height adjust automatically */
    object-fit: contain !important; /* Prevent cropping */
    background-size: contain !important;
}

.MuiCardMedia-root.makeStyles-media-15 {
  height: auto !important;
  min-height: 400px !important; 

}

.MuiCardMedia-root.makeStyles-media-15 {
  aspect-ratio: auto !important;
 
}




    /* Container adjustments */
  .row {
    margin: 0 !important;
    padding: 10px !important;
    width: 100% !important;
  }

  .col-sm-6.col-12 {
    padding: 10px !important;
    margin: 0 !important;
  }

  /* Button and content adjustments */
  .MuiButtonBase-root.MuiCardActionArea-root {
    width: 100% !important;
    height: 100% !important;
  }

  .MuiCardContent-root {
    padding: 0px !important;
    margin-top: 14px;
    padding-left: 8px  !important;;
    padding-right: 8px !important;;

  } 
  /* Fix for any overflow issues */
  .makeStyles-cardActions-16 {
    padding: 8px !important;
  }

  /* // Ensure proper spacing between cards */
  .MuiGrid-root .MuiGrid-item {
    padding: 8px !important;
  }

  
}

  .product-view .text {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    color: #ffffff;
  }
  .product-view .text h3 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .product-view .image-wrapper img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 90%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  

/* Default color for both mobile and desktop */
.expansion-panel .MuiIconButton-root .MuiSvgIcon-root.ExpandMoreIcon {
  color: black;
}

/* Mobile view (max-width: 767px) */
@media (max-width: 767px) {
  .expansion-panel .MuiIconButton-root .MuiSvgIcon-root.ExpandMoreIcon {
    color: white;
  }
}

/* Desktop view (min-width: 768px) */
@media (min-width: 768px) {
  .expansion-panel .MuiIconButton-root .MuiSvgIcon-root.ExpandMoreIcon {
    color: black;
  }
}
.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust the margin-top value according to your design */
}

.dot {
  width: 10px;
  height: 10px;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(189, 189, 189); /* Default color of the inactive dot */
  margin: 0 5px; /* Adjust the margin between dots */
}

.active {
  background-color: rgb(255, 255, 255); /* Color of the active dot */
}

/* Add this to your CSS file or style tag */
.mobile-fixed-button {
  display: none; /* Hide by default */
}

.mobile_search{
  width: 99vw;
  padding: 0px 50px;
  background: rgba(0, 0, 0, .1) !important;
  position: absolute;
  border-radius: 10px !important;
  color: white;
  outline: none !important; 
}

.cust_search::placeholder{
  color: rgb(158, 158, 158);
}


.cust_search{
  border-radius: 16px !important;
  width: 79%;
  padding: 13px 16px !important;
  border: 1px solid #444444;
  background: #444444 !important;
  padding: 10px 6px;
  position: absolute;
  color: rgb(200, 200, 200);
  outline: none !important;
  top: 8px;
}

@media only screen and (max-width: 600px) {
  .mobile-fixed-button {
    display: block; /* Show only on screens with a max-width of 600px (adjust as needed) */
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  body{
    background: black !important;
  }
}
